.footer {
  border-top: 1px solid #1a1a1a;
  color: white;
  padding: 40px 20px;
}

.footerContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.footerSection {
  flex: 1;
  min-width: 220px;
}

.sectionTitle {
  color: #f7b500;
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
}

.companyDescription {
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.6;
}

.list {
  list-style: none;
  padding: 0;
}

.link {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.link:hover {
  color: #f7b500;
}

.contactInfo,
.openingInfo,
.newsletterInfo {
  margin-bottom: 10px;
  font-size: 14px;
}

.socialIcons {
  margin-top: 10px;
}

.icon {
  color: white;
  margin-right: 10px;
  font-size: 18px;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #f7b500;
}

.newsletterForm {
  display: flex;
  flex-direction: column;
}

.input {
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
}

.button {
  padding: 10px;
  border: none;
  background-color: #f7b500;
  color: black;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #e0a400;
}

.footerBottom {
  text-align: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #333;
}

.footerLinks {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}

.footerLinks li {
  display: inline-block;
  margin-right: 15px;
}

.footerLinks .link {
  color: #f7b500;
}

.footerLinks .link:hover {
  color: white;
}