.container {
  width: 100%;
  display: flex;
  padding: 1rem var(--page_padding);
  justify-content: space-between;
  background-color: #000;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
  position: relative;
  z-index: 100;
  margin: 0;
  font-family: Arial, sans-serif;

}

.logo {
  width: 107px;
  flex-shrink: 0;
  margin-top: -2px;

}

.logo a {
  display: inline-block;
  height: 30px;
}

.logo a img {
  width: 220px;
  height: 170%;
  margin-bottom: -10px;
}

.logo_text {
  color: #ff6600;
  /* Orange text for logo */
  font-size: 1rem;
  font-weight: 700;
  margin-top: -2px;

}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons {
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.nav ul,
.icons ul {
  display: flex;
}

.nav ul li,
.icons ul li {
  margin-right: 1rem;
}

.nav ul li:last-child,
.icons ul li:last-child {
  margin-right: 0rem;
}

.nav ul li a {
  color: #fff;
  display: inline-block;
  padding-bottom: 0.2rem;
  position: relative;
  font-family: "Roboto Mono", monospace;
}

.nav ul li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ff6600;
  transform: scaleX(0);
  transition: all 0.2s linear;
}

.nav ul li a:hover,
.nav ul li .active {
  color: #ff6600;
}

.nav ul li a:hover::after,
.nav ul li .active::after {
  transform: scaleX(1);
}

.icons ul li a {
  width: 35px;
  height: 35px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
}

.icons ul li a::after {
  content: "";
  background-color: #ff6600;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.2s linear;
  border-radius: 50%;
  transform: scale(0);
}

.icons ul li a:hover:after,
.icons ul li .active::after {
  transform: scale(1);
}

.icons ul li a img {
  width: 16px;
  height: 16px;
  z-index: 1;
  transition: all 0.2s linear;
}

.icons ul li a:hover img,
.icons ul li .active img {
  filter: invert();
}

.navMobile {
  display: none;
}

.icons ul li:last-child a {
  display: none;
}

@media screen and (max-width: 1180px) {
  .search_box {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .search_box {
    width: 100%;
  }

  .nav {
    display: none;
  }

  .logo,
  .icons {
    width: 50%;
  }

  .logo {
    flex-shrink: 1;
    margin-top: -2px;
  }

  .icons {
    justify-content: flex-end;
  }

  .icons ul li:last-child a {
    display: inline-flex;
  }

  .navMobile {
    display: none;
    width: 100%;
    height: calc(100vh - 37px - 3rem);
    padding: 1.5rem;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
    text-align: center;
  }

  .navMobile ul li a {
    display: inline-block;
    width: 100%;
    padding: 1rem;
    color: #000;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    border-radius: 0.3rem;
  }

  .navMobile ul li:last-child a {
    margin-bottom: 0rem;
  }
}

@media screen and (max-width: 450px) {
  .logo {
    display: flex;
    align-items: center;
  }

  .logo a {
    height: 20px;
  }

  .logo_text {
    color: #ff6600;
    /* Orange text for logo */
    font-size: .7rem;
    font-weight: 700;
    margin-top: -1px;

  }

  .icons ul li a {
    width: 25px;
    height: 25px;
  }

  .icons ul li a img {
    width: 14px;
    height: 14px;
    margin-top: 2rem;
  }

  .navMobile {
    height: calc(100vh - 26.5px - 3rem);
  }
}

.display {
  display: block;
}

.hide {
  display: none;
}

.overflow_hide {
  overflow: hidden;
}